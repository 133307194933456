import { CSSProperties } from 'react';

export class CreateLegalNoticeStyle
{
  static mainContainerStyle(): CSSProperties
  {
    return {
      width: '100%',
      position: 'sticky',
      top: 100
    };
  }

  static sideContainerStyle(): CSSProperties
  {
    return {
      display: 'grid',
      gridTemplateColumns: '3fr 2fr',
      gap: '20px',
      marginBottom: 20,
      marginTop: 20,
      height: '100%'
    };
  }

  static headerStickyStyle(): CSSProperties
  {
    return {
      position: 'sticky',
      top: '95px',
      paddingBottom: '70px'
    };
  }

  static optionsContainerStyle(): CSSProperties
  {
    return {
      width: '100%',
      height: 'calc(100vh - 180px)' ,
      backgroundColor: 'rgb(238, 243, 255)',
      borderRadius: '8px',
      display: 'grid',
      gridTemplateRows: '50px auto 50px',
      padding: 5
    };
  }

  static contentContainerStyle(): CSSProperties
  {
    return {
      padding: '10px',
      height: '100%',
    };
  }


  static displayFlexBetween(): CSSProperties
  {
    return {
      display: 'flex',
      width: '100%',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: 10
    };
  }
}