import React, { Component, ReactElement } from 'react';
import { withGlobalContext } from '@/Context/Global/Global.context.wrapper';
import HeroSection from '@/Modules/App/Components/HeroSection/HeroSection';
import { LegalNoticeProps, LegalNoticeState } from '@/Modules/LegalNotice/Common/LegalNotice.interface';
import { LegalNoticeAdminService } from '@/Modules/LegalNotice/Admin/Show/LegalNoticeAdmin.service';
import LoaderComponent from '@/Modules/App/Components/LoaderComponent';
import PublishStateEnum from '@/Enum/PublishStateEnum';
import LoaderFullPage from '@/Modules/App/Components/Loader/LoaderFullPage/LoaderFullPage';
import { ContentStyles } from '@/Modules/App/Components/Content/Content.styles';
import LegalNoticeContent from '@/Modules/LegalNotice/Components/LegalNoticeContent/LegalNoticeContent';
import SectionCollapsed from '@/Modules/App/Components/Sections/SectionCollapsed/SectionCollapsed';
import { colors } from '@/Modules/App/Style/Variables/Colors.styles';
import Tag from '@/Modules/App/Components/Library/Tag/Tag';
import LegalNoticeControls from '@/Modules/LegalNotice/Components/LegalNoticeControls/LegalNoticeControls';
import LegalNoticeDetails from '@/Modules/LegalNotice/Components/LegalNoticeDetails/LegalNoticeDetails';
import LegalNoticePriceDetails from '@/Modules/LegalNotice/Components/LegalNoticePriceDetails/LegalNoticePriceDetails';
import LegalNoticeDocuments from '@/Modules/LegalNotice/Components/LegalNoticeDocuments/LegalNoticeDocuments';
import LegalNoticeHeadOfInvoice
	from '@/Modules/LegalNotice/Components/LegalNoticeHeadOfInvoice/LegalNoticeHeadOfInvoice';
import LegalNoticeSendToDetails
	from '@/Modules/LegalNotice/Components/LegalNoticeSendToDetails/LegalNoticeSendToDetails';
import {
	LegalNoticeControlsState
} from '@/Modules/LegalNotice/Components/LegalNoticeControls/LegalNoticeControls.interface';

class LegalNoticeAdmin extends Component<LegalNoticeProps, LegalNoticeState>
{
	private legalNoticeAdminService: LegalNoticeAdminService = new LegalNoticeAdminService();

	constructor(props: LegalNoticeProps)
	{
		super(props);

		// Config service
		this.legalNoticeAdminService.setProps(this.props);
		this.legalNoticeAdminService.subscribeState(this);

		// State & props
		this.state = this.legalNoticeAdminService.getState();

		// Bind
		this.extendTitleRender = this.extendTitleRender.bind(this);
	}

	//<editor-fold desc="Effects methods" defaultstate="collapsed">

	async componentDidMount(): Promise<void>
	{
		const legalNoticeId = this.props.params.legalNoticeId;

		if (legalNoticeId) {
			await this.legalNoticeAdminService.init(parseInt(legalNoticeId));
		}
	}

	componentWillUnmount(): void
	{
		this.legalNoticeAdminService.unsubscribe();
	}

	//</editor-fold>

	render(): ReactElement
	{
		// Extracting "from" parameter from the URL
		const searchParams = new URLSearchParams(window.location.search);
		const fromUrl = searchParams.get('from');

		// State
		const { legalNotice } = this.state;

		if (this.state.isLoading) {
			return (<LoaderFullPage/>);
		}

		return (
			<>
				<HeroSection
					title={ '' }
					icon={ null }
					button={ {
						label: 'Retour',
						variant: 'secondary',
						iconName: 'LuArrowLeft',
						onClick: () =>
						{
							if (fromUrl) {
								this.props.navigation(fromUrl);
							} else {
								this.props.navigation('/admin/legal-notices');
							}
						}
					} }
					extendTitle={ (this.state.legalNotice) ? this.extendTitleRender() : <LoaderComponent/> }
				/>

				<div style={ ContentStyles.sidebar }>
					{/* CONTENT */ }
					<div style={ { display: 'flex', flexDirection: 'column', gap: 50, paddingBottom: 100 } }>
						<LegalNoticeControls
							legalNotice={ legalNotice }
							getComponentState={ (LegalNoticeControlsState: LegalNoticeControlsState) =>
								this.legalNoticeAdminService.handleAction(LegalNoticeControlsState, this.state)
							}
						/>
						<SectionCollapsed title={ 'Détails' } highlightColor={ colors.gray100 }>
							<LegalNoticeDetails legalNotice={ legalNotice }/>
						</SectionCollapsed>

						<SectionCollapsed title={ 'Facturation' } highlightColor={ colors.gray100 }>
								<LegalNoticeHeadOfInvoice legalNotice={ legalNotice }/>
								<LegalNoticePriceDetails
									legalNotice={ legalNotice }
									selectedDepartment={ legalNotice.publishDepartment }
									selectedNewspaper={ legalNotice.newspaper }
									consumer={ legalNotice.consumer }
									selectedCategory={ legalNotice.formBuilderCategory }
								/>
						</SectionCollapsed>
						<SectionCollapsed title={ 'Envoie des documents' } highlightColor={ colors.gray100 }>
							<LegalNoticeSendToDetails legalNotice={ legalNotice }/>
						</SectionCollapsed>
					</div>
					{/* SIDEBAR */ }
					<div style={ { display: 'flex', flexDirection: 'column', gap: 20 } }>
						<SectionCollapsed isCollapsed={ false } title={ 'Documents' }>
							<LegalNoticeDocuments key={ this.legalNoticeAdminService.handleAction.toString() } legalNotice={ legalNotice }/>
						</SectionCollapsed>

						<SectionCollapsed isCollapsed={ false } title={ 'Rendu' }>
							<LegalNoticeContent legalNotice={ legalNotice }/>
						</SectionCollapsed>
					</div>
				</div>
			</>
		);
	}

	//<editor-fold desc="Private methods" defaultstate="collapsed">

	private extendTitleRender(): ReactElement
	{
		const stateEnum: PublishStateEnum | undefined = this.state.legalNotice?.status
			? PublishStateEnum.findByValue(this.state.legalNotice.status.toString())
			: undefined
		;

		return (
			<>
				<div style={ { display: 'flex', gap: 16, alignItems: 'center' } }>
					<div> Annonce Légale { this.state.legalNotice?.tag } </div>
					{ this.state.legalNotice &&
            <Tag
              style={ { display: 'inline-flex', height: 20, fontSize: 12, fontWeight: 500 } }
              value={ stateEnum?.value as string }
              enumName={ 'PublishStateEnum' }
            />
					}
				</div>
			</>
		);
	}

	//</editor-fold>
}

export default withGlobalContext(LegalNoticeAdmin);