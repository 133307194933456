import { BaseComponentService } from '@/Modules/App/Services/Common/BaseComponentService';
import { LegalNoticeControlsProps, LegalNoticeControlsState } from './LegalNoticeControls.interface';
import { LegalNoticeAdminService } from '@/Modules/LegalNotice/Admin/Show/LegalNoticeAdmin.service';
import { LegalNoticeFlagsInterface } from '@/Modules/LegalNotice/Common/LegalNotice.interface';
import { isPast, parseISO } from 'date-fns';
import PublishStateEnum from '@/Enum/PublishStateEnum';
import QuoteStatusEnum from '@/Enum/QuoteStatusEnum';
import PaymentStateEnum from '@/Enum/PaymentStateEnum';
import { ApiAdminLegalNoticeService } from '@/Service/Admin/ApiAdminLegalNoticeService';
import Swal from 'sweetalert2';
import { LegalNoticePriceService } from '@/Service/LegalNoticePriceService';
import { UserService } from '@/Modules/App/Services/User/User.service';
import { LegalNoticeInterface } from '@/Modules/LegalNotice/Interface/LegalNoticeInterface';
import { ApiAdminLegalNoticeFileService } from '@/Service/Admin/ApiAdminLegalNoticeFileService';

const initState: LegalNoticeControlsState = {
	flags: {} as LegalNoticeFlagsInterface,
	isDeleted: false,
	isDuplicate: false,
	isQuoteResponse: false,
	isLoadingDocument: false,
};

export class LegalNoticeControlsService extends BaseComponentService<LegalNoticeControlsProps, LegalNoticeControlsState>
{
	private legalNoticeAdminService: LegalNoticeAdminService = new LegalNoticeAdminService();
	private apiAdminLegalNoticeService = new ApiAdminLegalNoticeService();
	private apiAdminLegalNoticeFilesService = new ApiAdminLegalNoticeFileService();
	private legalNoticePriceService: LegalNoticePriceService;

	constructor()
	{
		super({} as LegalNoticeControlsProps, initState);

		// Services
		this.legalNoticePriceService = new LegalNoticePriceService(UserService.isAdmin() || UserService.isSuperAdmin());
	}

	/**
	 * Initialize the service by setting the context list and name
	 * @return Promise<void>
	 * @public
	 */
	async init(): Promise<void>
	{
		this.handleFlagsState();
	}

	/**
	 * Set Flags state
	 * @return void
	 * @public
	 */
	handleFlagsState(): void
	{
		this.setState({
			flags: this.getLegalNoticeFlags()
		});
	}

	/**
	 * Duplicate LegalNotice
	 */
	async onDuplicate(): Promise<void>
	{
		try {
			if (this.props.legalNotice) {

				const result = await Swal.fire({
					title: `Voulez-vous vraiment Dupliquer cette annonce ?`,
					text: 'Attention cette action sera irréversible',
					showCancelButton: true,
					confirmButtonColor: '#3085d6',
					cancelButtonColor: '#d33',
					confirmButtonText: `Oui, je veux Dupliquer !`,
					cancelButtonText: 'Non, Je ne veux pas Dupliquer'
				});

				if (result.isConfirmed) {
					Swal.fire({
						title: `Duplication en cours !`,
						text: `L'annonce va être Dupliquer.`,
						icon: 'info'
					});

					await this.apiAdminLegalNoticeService.duplicate(this.props.legalNotice.id);

					this.setState({ isDuplicate: true }, () => this.props.getComponentState(this.state));
				}
			}
		} catch (error: any) {
			console.error(`Error occurred on ${ this.constructor.name }: duplicate LegalNotice`, error);
		}
	}

	/**
	 * Handle Respond Quote
	 */
	async onRespondQuote(): Promise<void>
	{
		const { legalNotice } = this.props;

		try {
			const result = await Swal.fire({
				title: `Gestion du devis ?`,
				text: 'Voulez-vous accepter ce Devis ? Si oui, cette action va générer une facture et envoyer un mail',
				showCancelButton: true,
				confirmButtonColor: '#3085d6',
				cancelButtonColor: '#d33',
				confirmButtonText: `Oui, j'accepte ce Devis !`,
				cancelButtonText: 'Non, Je n\'accepte pas ce Devis'
			});

			if (legalNotice) {
				const nbCharacters = await this.legalNoticePriceService.nbCharacters(
					legalNotice.publishDepartment,
					legalNotice.formBuilderCategory,
					legalNotice,
					(legalNotice.option.isHeader)
						? this.legalNoticePriceService.countTempHeaderContent(legalNotice.consumer)
						: 0
				);

				if (result.isConfirmed) {
					await this.apiAdminLegalNoticeService.respondQuote(
						legalNotice?.id as number,
						{
							respond: 'ACCEPTED',
							legalNotice: { nbCharacters }
						}
					);

					// Call api to created file
					this.callApiFiles(legalNotice, nbCharacters);

					this.setState({ isQuoteResponse: true }, () => this.props.getComponentState(this.state));

				} else if (result.dismiss === Swal.DismissReason.cancel) {
					await this.apiAdminLegalNoticeService.respondQuote(
						legalNotice?.id as number,
						{ respond: 'REFUSED', legalNotice: { nbCharacters: 0 } }
					);
				}
			}

		} catch (error) {
			console.error('Error handling quote response:', error);
		}
	}

	/**
	 * Handle Delete LegalNotice
	 */
	async onDeleted(props: LegalNoticeControlsProps): Promise<void>
	{
		try {
			const result = await Swal.fire({
				title: `Voulez-vous vraiment Annuler cette annonce ?`,
				text: 'Attention cette action sera irréversible',
				showCancelButton: true,
				confirmButtonColor: '#3085d6',
				cancelButtonColor: '#d33',
				confirmButtonText: `Oui, je veux Annuler !`,
				cancelButtonText: 'Non, Je ne veux pas Annuler'
			});

			if (result.isConfirmed) {
				Swal.fire({
					title: `Annulation en cours !`,
					text: `L'annonce va être Annuler.`,
					icon: 'info'
				});

				const nbCharacters = await this.legalNoticePriceService.nbCharacters(
					this.props.legalNotice.publishDepartment,
					this.props.legalNotice.formBuilderCategory,
					this.props.legalNotice,
					(this.props.legalNotice.option.isHeader)
						? this.legalNoticePriceService.countTempHeaderContent(this.props.legalNotice.consumer)
						: 0
				);

				await this.apiAdminLegalNoticeService.delete(props.legalNotice.id, nbCharacters);

				this.setState({ isDeleted: true }, () => this.props.getComponentState(this.state));

			}
		} catch (error) {
			console.error(`Error occurred on ${ this.constructor.name }: Delete LegalNotice`, error);
		}
	}

	/**
	 * Get LegalNotice Flags to handle rights controls
	 * @return LegalNoticeFlagsInterface
	 * @private
	 */
	private getLegalNoticeFlags(): LegalNoticeFlagsInterface
	{
		const { legalNotice } = this.props;

		// Verify if publishDate exists before using parseISO
		const isPublicationDatePast = legalNotice.publishDate ? isPast(parseISO(legalNotice.publishDate)) : false;
		const isSupplier = !!legalNotice.extSupplierUid;
		const isDraft = legalNotice.status?.toString() === PublishStateEnum.DRAFT.value;
		const isCancelled = legalNotice.status?.toString() === PublishStateEnum.CANCEL.value;
		const isQuote = legalNotice.status?.toString() === PublishStateEnum.QUOTE.value;
		const isQuotePending = legalNotice.quoteStatus?.toString() === QuoteStatusEnum.PENDING.value;
		const isQuoteAnswerRefused = legalNotice.quoteStatus?.toString() === QuoteStatusEnum.REFUSED.value;
		const isQuoteAnswerAccepted = legalNotice.quoteStatus?.toString() === QuoteStatusEnum.ACCEPTED.value;
		const isPaid = legalNotice.paymentStatus?.toString() === PaymentStateEnum.PAID.value;
		const isPublished = legalNotice.status?.toString() === PublishStateEnum.PUBLISH.value;

		const canEdit = !isPublished && (isDraft || isQuote || (!isCancelled && !isPublicationDatePast));

		return {
			isPublicationDatePast,
			isSupplier,
			isDraft,
			isCancelled,
			isQuote,
			isQuotePending,
			isQuoteAnswerRefused,
			isQuoteAnswerAccepted,
			isPaid,
			isPublished,
			canEdit,
		} as const;
	}

	/**
	 * Handle Files service
	 * @param legalNotice
	 * @param nbCharacters
	 * @private
	 */
	private async callApiFiles(legalNotice: LegalNoticeInterface, nbCharacters: number): Promise<void>
	{

		const prepareDataForCertificate = {
			legalNotice: {
				title: legalNotice.title,
				content: legalNotice.content,
				signature: legalNotice.signature,
				price: legalNotice.price
			}
		};

		this.setState({ isLoadingDocument: true }, () => this.props.getComponentState(this.state));

		try {
			// Call createBillingFile and handle result
			await this.apiAdminLegalNoticeFilesService.createBillingFile(
				this.props.legalNotice?.id as number,
				{ legalNotice: { nbCharacters } }
			);

			// if discount, create credit note file
			if (legalNotice.discount > 0) {
				await this.apiAdminLegalNoticeFilesService.createCreditNoteFile(
					legalNotice.id as number,
					prepareDataForCertificate
				);
			}

			// call createCertificateFile independently
			await this.apiAdminLegalNoticeFilesService.createCertificateFile(
				legalNotice.id as number,
				prepareDataForCertificate
			);
		} catch (error) {
			console.error(error);
		} finally {
			//Set Loading
			this.setState({ isLoadingDocument: false }, () => this.props.getComponentState(this.state));
		}
	}
}