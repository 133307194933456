import { BaseComponentService } from '@/Modules/App/Services/Common/BaseComponentService';
import {
	CollaboratorShowProps,
	CollaboratorShowState
} from '@/Modules/Collaborator/Admin/Show/CollaboratorShow.interface';
import ActiveStatusEnum from '@/Enum/ActiveStatusEnum';
import { ApiAdminCollaboratorService } from '@/Service/Admin/ApiAdminCollaboratorService';
import {
	AssociateSellsyContactState
} from '@/Modules/App/Components/Sellsy/AssociateSellsyContact/AssociateSellsyContact.interface';

const initState: CollaboratorShowState = {
	isLoading: true,
	isEditMode: false,
	formData: {
		email: '',
		status: null,
		role: undefined,
		extSellsyId: null,
	}
};

export class CollaboratorShowService extends BaseComponentService<CollaboratorShowProps, CollaboratorShowState>
{
	private apiAdminCollaboratorService: ApiAdminCollaboratorService = new ApiAdminCollaboratorService();

	constructor()
	{
		super({} as CollaboratorShowProps, initState);

		// Bind
		this.handleEditSellsyId = this.handleEditSellsyId.bind(this);
	}

	init(): void
	{
		this.prepareFormData(this.props.collaborator);
	}

	/**
	 * Submit form data to update Collaborator
	 * @param currentState
	 * @return void
	 * @public
	 */
	async onUpdate(currentState: CollaboratorShowState): Promise<void>
	{
		this.props.modalContext?.isLoadingOverlay(true);

		console.log(currentState);
		console.log(this.props.collaborator);

		try {
			await this.apiAdminCollaboratorService.edit(currentState.formData, this.props.client?.id!, this.props.collaborator.id);
			this.props.getComponentState(currentState);

			this.prepareFormData(currentState.formData);
			this.props.modalContext?.isOpen(false);

		} catch (error) {
			console.error('Error occurred on update Collaborator');
		}
	}

	/**
	 * Handle Status collaborator
	 * @param status
	 * @public
	 */
	async handleStatus(status: string): Promise<void>
	{
		// Init var
		const newStatus: string = status === ActiveStatusEnum.ACTIVE.value ? ActiveStatusEnum.INACTIVE.value : ActiveStatusEnum.ACTIVE.value;

		this.setState(prevState => ({
			formData: {
				...prevState.formData,
				status: ActiveStatusEnum.findByValue(newStatus) || null
			}
		}));
	}

	/**
	 * Handle Edit Sellsy Id
	 * @param state
	 */
	handleEditSellsyId(state: AssociateSellsyContactState): void
	{
		this.setState({
			isEditMode: true,
			formData: {
				...this.state.formData,
				extSellsyId: state.contactSellsySelected?.id as number,
			}
		});
	}

	/**
	 * Prepare data to update collaborator
	 * @private
	 */
	private prepareFormData(formData: any): void
	{
		console.log(this.props.collaborator);
		this.setState({
			formData: {
				email: formData.email,
				status: formData.status,
				role: formData.role,
				extSellsyId: formData.extSellsyId
			}
		}, () => this.props.modalContext?.isLoadingOverlay(false));
	}
}