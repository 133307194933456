import React, { Component, ReactElement } from 'react';
import { LegalNoticeContentProps, LegalNoticeContentState } from './LegalNoticeContent.interface';
import { LegalNoticeContentService } from './LegalNoticeContent.service';
import {
	LegalNoticeContentStyles
} from '@/Modules/LegalNotice/Components/LegalNoticeContent/LegalNoticeContent.styles';
import LegalNoticeRenderHeader from '@/Modules/LegalNotice/Components/Render/LegalNoticeRenderHeader';
import { ConsumerDataInterface } from '@/Modules/LegalNotice/Interface/ConsumerDataInterface';
import { LegalNoticeRenderStyle } from '@/Modules/LegalNotice/Style/LegalNoticeRenderStyle';
import FormattedContent from '@/Modules/App/Components/FormattedContentHtmlComposent';

class LegalNoticeContent extends Component<LegalNoticeContentProps, LegalNoticeContentState>
{
	private legalNoticeContentService = new LegalNoticeContentService();

	constructor(props: LegalNoticeContentProps)
	{
		super(props);

		// Config service
		this.legalNoticeContentService.setProps(this.props);
		this.legalNoticeContentService.subscribeState(this);

		// State
		this.state = this.legalNoticeContentService.getState();
	}

	//<editor-fold desc="Effects methods" defaultstate="collapsed">

	async componentDidMount(): Promise<void>
	{
		await this.legalNoticeContentService.init();
	}

	componentWillUnmount(): void
	{
		this.legalNoticeContentService.unsubscribe();
	}

	//</editor-fold>

	render(): ReactElement
	{
		// Props
		const { legalNotice } = this.props;
		return (
			<div style={ LegalNoticeContentStyles.container }>
				<div style={ { width: 288 } }>
					{ legalNotice?.option.isLogo &&
            <>
              <div style={ { display: 'flex', justifyContent: 'center' } }>
                <img style={ { width: '250px' } } src={ legalNotice.logo } alt={ legalNotice?.id + '-logo' }/>
              </div>
              <div style={ { display: 'flex', justifyContent: 'center', marginBottom: '10px' } }>
                <hr style={ LegalNoticeRenderStyle.borderStyle() }/>
              </div>
            </>
					}
					{ legalNotice?.option.isHeader && this.headerContent(legalNotice.consumer) }
					<div style={ { textAlign: 'center', fontWeight: 'bold', overflowWrap: 'break-word' } }>
						{ legalNotice?.title }
					</div>
					{
						(legalNotice?.title !== '' && legalNotice?.title !== undefined) &&
            <div style={ { display: 'flex', justifyContent: 'center', marginBottom: '10px' } }>
              <hr style={ LegalNoticeRenderStyle.borderStyle() }/>
            </div>
					}
					<div lang={ 'fr' } style={ {
						textAlign: 'justify',
						hyphens: 'auto',
						msHyphens: 'auto',
						WebkitHyphens: 'auto',
						overflowWrap: 'break-word',
						wordSpacing: '-1px'
					} }>
						<FormattedContent content={ legalNotice?.content }/>
					</div>
					<div style={ { textAlign: 'end', overflowWrap: 'break-word' } }>
						{ legalNotice?.signature }
					</div>
				</div>
			</div>
		);
	}

	private headerContent(consumerData: any): ReactElement
	{
		return (
			<>
				<div style={ { textAlign: 'center' } }>
					<div><b>{ consumerData.name }</b></div>
					<div>{ consumerData.legalStatus }</div>
					<div>au capital de { consumerData.capital } €</div>
					<div>
						siège social: { consumerData.address.number ? `${ consumerData.address.number }, ` : '' }
						{ consumerData.address.street }
					</div>
					{ (consumerData.address.additionalData) &&
            <div>{ consumerData.address.additionalData }</div>
					}
					<div>{ consumerData.address.zipCode } { consumerData.address.city }</div>
					{ (consumerData.siren && consumerData.rcs) &&
            <div>{ consumerData.siren } RCS { consumerData.rcs }</div>
					}
				</div>
				<div style={ { display: 'flex', justifyContent: 'center', marginBottom: '10px' } }>
					<hr
						style={ { borderWidth: '2px', borderColor: '#000', width: '150px', margin: '10px 0 0 0', opacity: '1' } }
					/>
				</div>
			</>
		);
	}

}

export default LegalNoticeContent;