import React, { Component, ReactElement } from 'react';
import { AssociateSellsyContactProps, AssociateSellsyContactState } from './AssociateSellsyContact.interface';
import { AssociateSellsyContactService } from './AssociateSellsyContact.service';
import { withGlobalContext } from '@/Context/Global/Global.context.wrapper';
import LoaderComponent from '@/Modules/App/Components/LoaderComponent';
import Select from '@/Modules/App/Components/Library/Select/Select';
import {
	AssociateSellsyContactStyles
} from '@/Modules/App/Components/Sellsy/AssociateSellsyContact/AssociateSellsyContact.styles';
import Button from '@/Modules/App/Components/Library/Button/Button.';
import { colors } from '@/Modules/App/Style/Variables/Colors.styles';
import { ContactSellsyInterface } from '@/Modules/Client/Interface/ContactSellsyInterface';

class AssociateSellsyContact extends Component<AssociateSellsyContactProps, AssociateSellsyContactState>
{
	private associateSellsyContactService = new AssociateSellsyContactService();
	private debounceTimeout: NodeJS.Timeout | null = null;

	constructor(props: AssociateSellsyContactProps)
	{
		super(props);

		// Config service
		this.associateSellsyContactService.setProps(this.props);
		this.associateSellsyContactService.subscribeState(this);

		// State
		this.state = this.associateSellsyContactService.getState();
	}

	//<editor-fold desc="Effects methods" defaultstate="collapsed">

	async componentDidMount(): Promise<void>
	{
		await this.associateSellsyContactService.init();
	}

	async componentDidUpdate(prevProps: AssociateSellsyContactProps): Promise<void> {
		if (
			prevProps.authCompanyContext !== this.props.authCompanyContext ||
			prevProps.client?.extSellsyId !== this.props.client?.extSellsyId ||
			prevProps.formData.collaboratorMail !== this.props.formData.collaboratorMail
		) {
			if (this.debounceTimeout) {
				clearTimeout(this.debounceTimeout);
			}

			this.debounceTimeout = setTimeout(async () => {
				this.setState({ isLoading: true });
				await this.associateSellsyContactService.refresh(this.props);
			}, 500);
		}
	}

	componentWillUnmount(): void
	{
		this.associateSellsyContactService.unsubscribe();
	}

	//</editor-fold>

	render(): ReactElement
	{
		if (this.state.isLoading) {
			return <LoaderComponent/>;
		}

		return (
			<div style={ AssociateSellsyContactStyles.container }>
				{ this.associateSellsyContactService.filterSellsyContacts().length > 0 && !this.state.contactSellsySelected ? (
					<>
						<Select
							key={ this.props.formData.toString() }
							label={ 'Associer le Collaborateur à un contact Sellsy' }
							textHelp={ 'Si tous les contacts sont déjà associés ou si l\'adresse e-mail ne correspond pas, veuillez créer un nouveau contact.' }
							listOptions={ this.associateSellsyContactService.filterSellsyContacts() }
							onSelect={ (contact: ContactSellsyInterface, event) =>
							{
								event.preventDefault();
								event.stopPropagation();
								this.associateSellsyContactService.handleContactSellsySelect(contact, event);
							} }
							displayKey={ 'email' }
							isRequired={ true }
						/>

						{ (!this.associateSellsyContactService.isEmailInFilteredContacts(this.props) && !this.state.contactSellsySelected) && (
							<div style={ AssociateSellsyContactStyles.create }>
								<Button
									variant={ 'sellsy' }
									label={ 'Créer un nouveau contact Sellsy' }
									onClick={ () => this.associateSellsyContactService.handleCreateNewSellsyContact(this.props.formData) }
								/>
							</div>
						) }
					</>
				) : (
					<>
						{
							!this.state.contactSellsySelected &&
              <div style={ AssociateSellsyContactStyles.notfound }>
                Tous les contacts Sellsy ont déjà été assignés. Veuillez créer un nouveau contact.
              </div>
						}

						{ (!this.state.contactSellsySelected?.id) ? (
							<div style={ AssociateSellsyContactStyles.create }>
								<Button
									variant={ 'sellsy' }
									label={ 'Créer un nouveau contact Sellsy' }
									onClick={ () => this.associateSellsyContactService.handleCreateNewSellsyContact(this.props.formData) }
								/>
							</div>
						) : (
							<div style={ AssociateSellsyContactStyles.associateContact }>
								<div>
									<img
										style={ AssociateSellsyContactStyles.sellsyLogo }
										src="/img/logo-sellsy.png" alt="Sellsy Logo"
									/>
									<div style={ { color: colors.purple500 } }>
										Le contact a bien été lié à Sellsy, vous pouvez créer votre collaborateur.
									</div>
								</div>
								<div>
									<Button
										variant={ 'smallDark' }
										label={ 'Annuler' }
										onClick={ this.associateSellsyContactService.handleCancel.bind(this) }
									/>
								</div>
							</div>
						) }
					</>
				) }
			</div>);
	}
}

export default withGlobalContext(AssociateSellsyContact);