import { BaseComponentService } from '@/Modules/App/Services/Common/BaseComponentService';
import {
	CollaboratorAdminFormCreateDefault,
	CollaboratorAdminFormCreateProps,
	CollaboratorAdminFormCreateState
} from './CollaboratorAdminFormCreate.interface';
import { AssociateUserState } from '@/Modules/User/Components/AssociateUser/AssociateUser.interface';
import CollaboratorRoleEnum from '@/Enum/CollaboratorRoleEnum';
import ActiveStatusEnum from '@/Enum/ActiveStatusEnum';
import { ApiAdminCollaboratorService } from '@/Service/Admin/ApiAdminCollaboratorService';
import {
	AssociateSellsyContactState
} from '@/Modules/App/Components/Sellsy/AssociateSellsyContact/AssociateSellsyContact.interface';

const initState: CollaboratorAdminFormCreateState = CollaboratorAdminFormCreateDefault();

export class CollaboratorAdminFormCreateService extends BaseComponentService<CollaboratorAdminFormCreateProps, CollaboratorAdminFormCreateState>
{
	private apiAdminCollaboratorService: ApiAdminCollaboratorService = new ApiAdminCollaboratorService();

	constructor()
	{
		super({} as CollaboratorAdminFormCreateProps, initState);

		// Bind
		this.handleSelectedUser = this.handleSelectedUser.bind(this);
		this.handleCollaboratorRole = this.handleCollaboratorRole.bind(this);
		this.handleChangeCollaboratorMail = this.handleChangeCollaboratorMail.bind(this);
		this.handleCreate = this.handleCreate.bind(this);
	}

	/**
	 * Initialize the service by setting the context list and name
	 * @return Promise<void>
	 */
	async init(): Promise<void>
	{
	}

	/**
	 * Get State form AssociateUser
	 * @param associateUserState
	 * @param currentState
	 */
	handleSelectedUser(associateUserState: AssociateUserState, currentState: CollaboratorAdminFormCreateState): void
	{
		this.setState({
			user: associateUserState.selectedUser,
			errorMessage: ''
		}, () =>
		{
			if (associateUserState.selectedUser === null) {
				this.setState({
					isUserEmail: true,
				});
			}

			this.props.modalContext.errorMessage(this.state.errorMessage);
		});

	}

	/**
	 * Handle if User email in used or new collaborator email
	 * @param event
	 */
	handleEmailUse(event: any): void
	{
		if (event === 'true') {
			this.setState({ isUserEmail: true });
		} else {
			this.setState({ isUserEmail: false });
		}
	}

	/**
	 * Handle Collaborator role
	 * @param role
	 */
	handleCollaboratorRole(role: keyof typeof CollaboratorRoleEnum): void
	{
		console.log(role);
		this.setState({
			role,
			errorMessage: '',
		}, () => this.props.modalContext.errorMessage(this.state.errorMessage));
	}

	/**
	 * Handle Collaborator Email
	 * @param event
	 */
	handleChangeCollaboratorMail(event: any): void
	{
		this.setState({
			collaboratorMail: event.target.value,
			errorMessage: '',
		}, () => this.props.modalContext.errorMessage(this.state.errorMessage));
	}

	/**
	 * Handle ExtSellsyId from AssociateSellsyContact
	 * @param getState
	 */
	handleExtSellsyId(getState: AssociateSellsyContactState): void
	{
		if (getState.contactSellsySelected?.id) {
			this.setState({
				extSellsyId: String(getState.contactSellsySelected?.id!)
			});
		} else {
			this.setState({
				extSellsyId: '',
			});
		}
	}

	/**
	 * Handle Create new Collaborator
	 * @param event
	 * @param currentState
	 * @param callback - Fonction à exécuter une fois que la création est terminée
	 */
	async handleCreate(event: any, currentState: CollaboratorAdminFormCreateState, callback?: () => void): Promise<void>
	{
		event.stopPropagation();
		this.props.modalContext.isLoadingOverlay(true);

		const validation = this.validateFormData(currentState);

		if (!validation.isValid) {
			this.props.modalContext.errorMessage(validation.error!);
			this.props.modalContext.isLoadingOverlay(false);
			return;
		}

		const collaboratorData = {
			email: currentState.collaboratorMail,
			status: ActiveStatusEnum.ACTIVE.value,
			role: currentState.role,
			user: currentState.user?.id,
			client: this.props.client?.id,
			extSellsyId: currentState.extSellsyId,
		};

		try {
			await this.apiAdminCollaboratorService.create(collaboratorData, this.props.client?.id)
				.then(async (response: any) =>
				{

					if (response.errorMessage) {
						this.setState({ errorMessage: response.errorMessage });
						this.props.modalContext.errorMessage(response.errorMessage);
						this.props.modalContext.isOpen(true);
						this.props.modalContext.isLoadingOverlay(false);
						return;
					}

					// Clear
					this.props.modalContext.isOpen(false);
					this.props.modalContext.isLoadingOverlay(false);
					this.props.modalContext.errorMessage('');

					// CallBack
					if (callback) callback();

				})
				.catch((error: any) =>
				{
					this.setState({ errorMessage: error.message });
				});

		} catch (error: any) {
			console.log('Issue from create collaborator : ', error.errorMessage);
		}
	}


	/**
	 * handle Validation full form
	 * @param currentState
	 * @private
	 */
	private validateFormData(currentState: CollaboratorAdminFormCreateState): { isValid: boolean, error?: string }
	{
		if (!currentState.user) {
			return { isValid: false, error: 'Un utilisateur doit être sélectionné.' };
		}

		if (!currentState.isUserEmail && !currentState.collaboratorMail) {
			return { isValid: false, error: 'Le mail du collaborateur est requis.' };
		}

		if (!currentState.role) {
			return { isValid: false, error: 'Le rôle du collaborateur est requis.' };
		}

		if (!currentState.extSellsyId) {
			return { isValid: false, error: 'Le contact doit être lié à un compte Sellsy' };
		}

		if (!this.props.client?.id) {
			return { isValid: false, error: 'L\'identifiant du client est requis.' };
		}

		return { isValid: true };
	}

}