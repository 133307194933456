import { CSSProperties } from 'react';
import { CssVariableEnum } from '@/Enum/CssVariableEnum';

export class LegalNoticePriceStyle
{
  static mainContainerStyle(): CSSProperties
  {
    return {
      position: 'absolute',
      zIndex: 200,
      top: 66,
      right: 100,
      width: 592,
      height: 50
    };
  }

  static bodyContainerStyle(): CSSProperties
  {
    return {
      display: 'flex',
      height: '100%',
      width: '100%',
      alignItems: 'flex-end',
      justifyContent: 'flex-end',
      gap: '20px'
    };
  }

  static compactContainerStyle(): CSSProperties
  {
    return {
      color: CssVariableEnum['--color-grey-900'],
      display: 'flex',
      alignItems: 'flex-end',
      justifyContent: 'flex-end',
      gap: 15
    };
  }
}