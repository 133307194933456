import { CSSProperties } from 'react';
import { colors } from '@/Modules/App/Style/Variables/Colors.styles';
import { boxShadows } from '@/Modules/App/Style/Variables/Variables.styles';

export const LoaderSwitchContextStyles = {
	wrapper: {
		position: 'absolute',
		zIndex: 5000,
		width: '100vw',
		top: 0,
		left: 0,
		height: '100vh',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		backgroundColor: 'rgba(251, 251, 251, 0.8)',
		transition: 'background 0.3s ease'
	} as CSSProperties,

	container: {
		width: 350,
		height: 150,
		borderRadius: 15,
		backgroundColor: colors.white,
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		flexDirection: 'column',
		gap: 20,
		...boxShadows.main
	} as CSSProperties,

} as const;