import { Component, ReactElement } from 'react';
import {
	LoaderSwitchContextProps, LoaderSwitchContextState
} from '@/Modules/App/Components/Loader/LoaderSwitchContext/LoaderSwitchContext.interface';
import {
	LoaderSwitchContextStyles
} from '@/Modules/App/Components/Loader/LoaderSwitchContext/LoaderSwitchContext.styles';
import { PuffLoader } from 'react-spinners';
import { colors } from '@/Modules/App/Style/Variables/Colors.styles';

class LoaderSwitchContext extends Component<LoaderSwitchContextProps, LoaderSwitchContextState>
{
	render(): ReactElement
	{
		return (
			<div style={ LoaderSwitchContextStyles.wrapper }>
				<div style={ LoaderSwitchContextStyles.container }>
					<PuffLoader
						color={ colors.blueRibbon500 }
						loading={ true }
						size={ 50 }
						aria-label="Loading Spinner"
						data-testid="loader"
					/>
					Changement d'entreprise en cours ...
				</div>
			</div>
		);
	}

}

export default LoaderSwitchContext;