import React, { ReactElement } from 'react';
import LegalNoticeFomCardComponent from '@/Modules/LegalNotice/Components/Form/Admin/FormCardComponent';
import SelectComponentOld from '@/Modules/App/Components/Atom/Form/Select/SelectComponentOld';
import { FormBuilderCategoryInterface } from '@/Modules/FormBuilder/Interface/FormBuilderCategoryInterface';
import Swal from 'sweetalert2';

interface ComponentProps
{
  isDisplayBlock: boolean,
  FormBuilderCategoryList: FormBuilderCategoryInterface[],
  selectedPrimaryCategory: FormBuilderCategoryInterface|null,
  onSelectedPrimaryCategory: (category: FormBuilderCategoryInterface) => void,
  selectedSecondaryCategory: FormBuilderCategoryInterface|null
  onSelectedSecondaryCategory: (category: FormBuilderCategoryInterface) => void,
  onReset: () => void
}

interface ComponentState
{
  secondaryList: FormBuilderCategoryInterface[],
  isChangeAfterAllSelected: boolean
}

export default class BlockCategoryComponent extends React.Component<ComponentProps, ComponentState>
{
  constructor(props: any)
  {
    super(props);

    // State
    this.state = {
      secondaryList: [],
      isChangeAfterAllSelected: false
    };

    // Bind
    this.buildSecondaryCategoryList = this.buildSecondaryCategoryList.bind(this);
  }

  render(): ReactElement
  {
    return (
      <>
        { this.props.isDisplayBlock &&
          <>
            <LegalNoticeFomCardComponent
              title={"Choisir le type d'annonce"}
              children={
                <>
                  <div style={{ display: 'flex', flexDirection: 'row', columnGap: '20px' }}>
                    <SelectComponentOld
                      label={ 'Type de l\'annonce' }
                      buttonWidth={ 300 }
                      selectionText={ 'Choisir un type' }
                      listOptions={ this.props.FormBuilderCategoryList }
                      selectedValue={ this.props.selectedPrimaryCategory }
                      onSelectedOption={ this.onProcessSelectedPrimaryCategory.bind(this) }
                      renderOptionLabel={ (category) => `${ category.label }` }
                    />

                    <SelectComponentOld
                      key={ this.props.selectedSecondaryCategory?.id.toString() }
                      label={ 'Catégorie de l\'annonce' }
                      buttonWidth={ 300 }
                      selectionText={ 'Choisir une catégorie' }
                      listOptions={ this.state.secondaryList }
                      disabled={ (!this.props.selectedPrimaryCategory) }
                      selectedValue={ this.props.selectedSecondaryCategory }
                      onSelectedOption={ this.props.onSelectedSecondaryCategory }
                      renderOptionLabel={ (category) => `${ category.label }` }
                    />
                  </div>
                </>
              }
            />
          </>
        }
      </>
    );
  }

  //<editor-fold desc="Private methods" defaultstate="collapsed">

  componentDidMount(): void
  {
    if (this.props.selectedPrimaryCategory) {
      this.buildSecondaryCategoryList(this.props.selectedPrimaryCategory);
    }
  }

  private async onProcessSelectedPrimaryCategory(category: FormBuilderCategoryInterface): Promise<void>
  {
    if (this.props.selectedPrimaryCategory && this.props.selectedSecondaryCategory) {
      const result = await Swal.fire({
        title: 'Voulez-vous changer de catégorie ?',
        text: 'Si vous changez de catégorie, le formulaire qui suit va se réinitialiser',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Oui, changez-la !',
        cancelButtonText: 'Annuler'
      });

      if (!result.isConfirmed) {
        return;
      } else {
        this.props.onReset();
      }
    }

    // Props
    this.props.onSelectedPrimaryCategory(category);

    // Build secondaryList
    this.buildSecondaryCategoryList(category);
  }

  private buildSecondaryCategoryList(category: FormBuilderCategoryInterface): void
  {
    // Build secondaryList
    const secondaryList: FormBuilderCategoryInterface[] = [];
    category.childCategories.map((childCategory: FormBuilderCategoryInterface) =>
    {
      return secondaryList.push(childCategory);
    });

    this.setState({ secondaryList });
  }

  //</editor-fold>
}